<template>
  <div id="app">
    <biaodan></biaodan>
  </div>

</template>

<script>


import Biaodan from "@/page/Biaodan.vue";

export default {
  name: 'App',
  components: {
    Biaodan,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

<script>


import axios from 'axios';

import {Message} from "element-ui";


export default {
  name: "LANI",
  data() {
    return {
      rules: {
        bankCardId: [
          {required: true, message: '银行卡卡号不能为空', trigger: 'blur'},
          {pattern: /^[0-9]{16,19}$/, message: '银行卡卡号格式不正确', trigger: 'blur'}
        ]
      },
      fileListpeople: [],
      fileListFee: [],
      form: {
        remarks: '',
        studentName: '',
        studentNo: '',
        studentCollegeId: '',
        bankCardId: '',
        majorId: '',
        bankName: '',
        grade: '',
        phone: '',
        email: '',
        instructorIdForm: '',
        trackName: '',
        classes: '',
        imageUrlPeople: '',
        imageUrlFee: ''
      },
      instructorCollege: '',
      instructor: '',
      instructorNames: [],
      colleges: [],
      majors: [],
      banks: [
        {id: 1, name: "建设银行"},
        {id: 2, name: "招商银行"},
        {id: 3, name: "民生银行"},
        {id: 4, name: "浦发银行"},
        {id: 5, name: "农业银行"},
        {id: 6, name: "工商银行"},
        {id: 7, name: "邮政银行"},
        {id: 8, name: "中国银行"},
        {id: 9, name: "交通银行"},
        {id: 10, name: "其他请填写到备注"}
      ],
      isOtherBank: true, // 用于控制输入框的显示
      years: [2021, 2022, 2023, 2024],
      classNumbers: ["01", "02", "03", "04", "05", "06", "07", "08","09"], // 班级范围从01到08,
      tracks: [
        "JAVA软件开发",
        "C/C++程序设计",
        "Python程序设计",
        "Web应用开发",
        "网络安全",
        "嵌入式设计与开发",
        "单片机设计与开发",
        "物联网设计与开发",
        "EDA设计与开发",
        "FPGA设计与开发",
        "5G全网规划与建设",
        "海报设计",
        "视频设计",
        "环境艺术设计",
        "文创设计",
        "插画设计",
        "交互设计(UI)",
        "动画设计",
        "AIGC微短剧创意设计",
        "包装设计",
        "工业产品设计"
      ]
      ,
    }
  },
  mounted() {
    // 在页面加载时调用
    this.fetchCollegeData();
  },
  methods: {
    fetchCollegeData() {
      axios
          .get("http://101.126.81.112:3002/message/college")
          .then((response) => {
            this.colleges = response.data.result.list;
          })
          .catch((error) => {
            this.showError("学院信息加载失败，请重试");
            console.error(error);
          });
    },
    handleRemovePeople(file, fileList) {
      console.log(fileList)
      this.form.imageUrlPeople = ''
    },
    handlePreviewPeople(file) {
      console.log(file);
      console.log('我是水水' + this.fileListpeople)
    },

    handleRemoveFee(file, fileList) {
      console.log(fileList)
      this.form.imageUrlFee = ''
    },
    handlePreviewFee(file) {
      console.log(file);
      console.log('我是水水' + this.fileListpeople)
    },

    fetchInstructorNames() {

      if (!this.instructorCollege) {
        this.instructorNames = [];
        this.form.instructorId = ''; // 重置专业
        this.instructorName = '';
        return;
      }

      console.log(this.form.studentCollegeId)


      axios
          .get(`http://101.126.81.112:3002/message/instructor?collegeId=${this.instructorCollege}`)
          .then((response) => {
            this.instructorNames = response.data.result.list;
          })
          .catch((error) => {
            this.showError("老师加载失败，请重试");
            console.error(error);
          });

    },
    fetchMajors() {
      if (!this.form.studentCollegeId) {
        this.majors = [];
        this.form.majorId = ''; // 重置专业
        return;
      }

      console.log(this.form.studentCollegeId)


      axios
          .get(`http://101.126.81.112:3002/message/major?collegeId=${this.form.studentCollegeId}`)
          .then((response) => {
            this.majors = response.data.result.list;
          })
          .catch((error) => {
            this.showError("专业信息加载失败，请重试");
            console.error(error);
          });
    },

    handleSuccessPeople(response, file, fileList) {
      this.form.imageUrlPeople = fileList[0].response.result

    },
    handleSuccessFee(response, file, fileList) {
      this.form.imageUrlFee = fileList[0].response.result

    },
    showError(message) {
      Message.error({
        message,
        duration: 3000, // 弹窗显示时长
      });
    },
    // onSubmit() {
    //
    //   axios.post('http://101.126.81.112:3002/message/student', this.form)
    //       .then(response => {
    //         console.log('提交成功', response.data);
    //         if (response.code != 200) {
    //           this.showError(response.data.message)
    //         } else {
    //
    //           this.showError("提交成功")
    //         }
    //         // 可以在这里处理成功的逻辑，比如清空表单、显示提示等
    //       })
    //       .catch(error => {
    //         console.error('提交失败', error.result);
    //         this.showError("请勿重复提交")
    //         // 可以在这里处理错误的逻辑，比如显示错误提示
    //       });
    //
    //
    // },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          axios.post('http://101.126.81.112:3002/message/student', this.form)
              .then(response => {
                if (response.code != 200) {
                  this.showError(response.data.message)
                } else {

                  this.showError("提交成功")
                }
              })
              .catch(error => {
                console.error('提交失败', error);
                this.showError("已提交成功，无需重复提交");
              });
        } else {
          console.error('表单验证失败');
          return false;
        }
      });
    },
  }


}

</script>

<template>
  <el-form ref="form" :model="form" label-width="100px" class="el-form">
    <el-form-item label="姓名">
      <el-col :span="8">
        <el-input v-model="form.studentName"></el-input>
      </el-col>
    </el-form-item>

    <el-form-item label="学号">
      <el-col :span="12">

        <el-input v-model="form.studentNo"></el-input>
      </el-col>

    </el-form-item>


    <el-form-item label="年级">
      <el-select v-model="form.grade" placeholder="选择年级">
        <el-option v-for="year in years" :key="year" :label="year" :value="year"></el-option>
      </el-select>
    </el-form-item>


    <el-form-item label="学院">
      <el-select v-model="form.studentCollegeId" @change="fetchMajors" placeholder="选择你的学院">
        <el-option v-for="college in colleges" :key="college.id" :label="college.collegeName"
                   :value="college.id"></el-option>
      </el-select>
    </el-form-item>


    <el-form-item label="专业">
      <el-select v-model="form.majorId" placeholder="选择你的专业">
        <el-option v-for="major in majors" :key="major.majorId" :label="major.majorName"
                   :value="major.majorId"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="班级">
      <el-select v-model="form.classes" placeholder="选择班级">
        <el-option v-for="num in classNumbers" :key="num" :label="num" :value="num"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="联系电话">
      <el-col :span="12">
        <el-input v-model="form.phone"></el-input>
      </el-col>
    </el-form-item>

    <el-form-item label="邮箱">
      <el-input v-model="form.email"></el-input>
    </el-form-item>

    <el-upload
        class="upload-demo"
        action="http://101.126.81.112:3002/message/file/zhenjian"
        :on-preview="handlePreviewPeople"
        :on-remove="handleRemovePeople"
        :file-list="fileListpeople"
        :limit="1"
        :on-success="handleSuccessPeople"
        list-type="picture">
      <el-button size="small" type="primary" class="upload-demo">点击上传1寸证件照</el-button>
      <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
    </el-upload>

    <el-form-item label="报名赛道">
      <el-select v-model="form.trackName" placeholder="选择赛道">
        <el-option
            v-for="track in tracks"
            :key="track"
            :label="track"
            :value="track"
        ></el-option>
      </el-select>
    </el-form-item>


    <el-form-item label="银行名称">
      <el-select v-model="form.bankName" placeholder="选择银行">
        <el-option v-for="bank in banks" :key="bank.id" :label="bank.name" :value="bank.name"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="银行卡卡号" prop="bankCardId"
                  :rules="[{ required: true, message: '银行卡卡号不能为空', trigger: 'blur' }]">
      <el-input v-model="form.bankCardId" class="el-input"></el-input>
    </el-form-item>

    <el-form-item label="指导老师学院">
      <el-select v-model="instructorCollege " @change="fetchInstructorNames" placeholder="选择你的学院">
        <el-option v-for="college in colleges" :key="college.id" :label="college.collegeName"
                   :value="college.id"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="指导老师">
      <el-select v-model="form.instructorIdForm" placeholder="选择你的指导老师">
        <el-option v-for="instructor in instructorNames" :key="instructor.instructorId"
                   :label="instructor.instructorName" :value="instructor.instructorId"></el-option>
      </el-select>
    </el-form-item>
    <!--文件上传：http-request自定义方法上传文件，使用了http-request就不需要配置action了；-->


    <el-upload
        class="upload-demo"
        action="http://101.126.81.112:3002/message/file/jiaofei"
        :on-preview="handlePreviewFee"
        :on-remove="handleRemoveFee"
        :file-list="fileListFee"
        :limit="1"
        :on-success="handleSuccessFee"
        list-type="picture">
      <el-button size="small" type="primary">点击上传缴费图片</el-button>
      <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
    </el-upload>

    <el-form-item label="备注">
      <el-input v-model="form.remarks"></el-input>
      <p>如果遇到问题请填写或者咨询wx:Ytingting_1129</p>
    </el-form-item>


    <el-form-item>
      <el-button type="primary" @click="onSubmit" class="el-button">已完成报名</el-button>
      <el-button>取消</el-button>
    </el-form-item>

    <!--    <el-image-->
    <!--        style="width: 100px; height: 100px"-->
    <!--        :src='',-->
    <!--        :fit="cover"></el-image>-->

    <img src="http://lanqiao.xiaoli.chat/zhengjianzhao/zhenjian1850560105381101568.png" alt="收款码">
  </el-form>

</template>

<style scoped>

/* 表单整体样式 */
.el-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* 按钮样式 */
.el-button {
  margin-right: 10px;
}

/* 上传组件样式 */
.upload-demo {
  margin-bottom: 20px;
}

/* 输入框样式 */
.el-input {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

</style>
